import { isPlatform } from "@ionic/react";
import { AppBar, Box, Container, CssBaseline, Drawer, IconButton, useTheme } from "@material-ui/core";
import clsx from "clsx";
import React, { ReactNode, useState } from "react";
import drawerLogoSm from "../../assets/images/logo-io.svg";
import drawerLogo from "../../assets/images/logo-io.svg";
import headerLogo from "../../assets/images/logo-progress-m.svg";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import HeaderProvider from "../HeaderProvider/HeaderProvider";
import Icon from "../Icon/Icon";
import Menu from "../Menu/Menu";
import useStyles from "./styles";

export const defaultDrawerWidth: number = 240;
export const defaultHeaderHeight: number = 75;
export const defaultFooterHeight: number = 20;

export type StylesProps = {
    drawerWidth?: number;
    headerHeight?: string;
    headerBarColor?: string;
    headerBarBackground?: string;
    drawerColor?: string;
    drawerBackground?: string;
    drawerHeaderColor?: string;
    drawerHeaderBackground?: string;
    drawerPaperColor?: string;
    drawerPaperBackground?: string;
    footerHeight?: string;
    footerBarColor?: string;
    footerBarBackground?: string;
    drawerDisabled?: boolean;
};

interface ILayoutProps extends React.HTMLAttributes<HTMLElement> {
    logo?: string;
    header?: {
        rightMenu?: ReactNode;
    };
    drawer?: {
        disabled?: boolean;
    };
    stylesProps?: StylesProps;
}

const Layout: React.FC<ILayoutProps> = (props: ILayoutProps) => {
    const { children, logo, header, drawer, stylesProps } = props;

    stylesProps!.drawerDisabled = !!drawer?.disabled;
    const classes = useStyles(stylesProps);
    const theme = useTheme();

    const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

    const handleDrawerOpen = () => {
        setDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setDrawerOpen(false);
    };

    return (
        <HeaderProvider>
            <Container className={classes.root} maxWidth={false}>
                <CssBaseline />

                <AppBar
                    elevation={0}
                    position="fixed"
                    className={clsx(classes.appBar, {
                        [classes.appBarShift]: !drawer?.disabled && drawerOpen,
                    })}
                >
                    <Header
                        drawerDisabled={drawer?.disabled}
                        logo={logo ? logo : headerLogo}
                        rightMenu={header?.rightMenu}
                        drawerOpen={drawerOpen}
                        handleDrawerOpen={handleDrawerOpen}
                        stylesProps={{
                            headerHeight: stylesProps?.headerHeight,
                        }}
                    />
                </AppBar>

                {drawer?.disabled ? null : (
                    <Drawer
                        variant="permanent"
                        className={clsx(classes.drawer, {
                            [classes.drawerOpen]: drawerOpen,
                            [classes.drawerClose]: !drawerOpen,
                        })}
                        classes={{
                            paper: clsx(classes.drawerPaper, {
                                [classes.drawerOpen]: drawerOpen,
                                [classes.drawerClose]: !drawerOpen,
                            }),
                            paperAnchorLeft: classes.drawerAnchorLeft,
                        }}
                    >
                        <div className={classes.drawerHeader}>
                            <Box
                                className={clsx(classes.logo, {
                                    [classes.logoShift]: drawerOpen,
                                })}
                                onClick={handleDrawerOpen}
                            >
                                <img alt="Simple Ionic Stack" src={drawerOpen ? drawerLogo : drawerLogoSm} />
                            </Box>

                            <IconButton color="inherit" size="small" onClick={handleDrawerClose}>
                                {theme.direction === "ltr" ? (
                                    <Icon name="chevron-left-circle" fill={theme.palette.primary.main} size={20} />
                                ) : (
                                    <Icon name="chevron-right-circle" fill={theme.palette.primary.main} size={20} />
                                )}
                            </IconButton>
                        </div>
                        <Menu
                            drawerOpen={drawerOpen}
                            handleDrawerOpen={handleDrawerOpen}
                            handleDrawerClose={handleDrawerClose}
                        />
                    </Drawer>
                )}

                <main
                    className={clsx(classes.main, {
                        [classes.mainShift]: drawerOpen,
                    })}
                >
                    <div
                        className={clsx(classes.content, {
                            [classes.hybridContent]: isPlatform("hybrid"),
                        })}
                    >
                        {children}
                    </div>
                </main>

                <AppBar
                    component="footer"
                    elevation={0}
                    className={clsx(classes.footerBar, {
                        [classes.footerBarShift]: drawerOpen,
                    })}
                >
                    <Footer
                        stylesProps={{
                            footerHeight: stylesProps?.footerHeight,
                            footerBarColor: stylesProps?.footerBarColor,
                            footerBarBackground: stylesProps?.footerBarBackground,
                        }}
                    />
                </AppBar>
            </Container>
        </HeaderProvider>
    );
};

export default Layout;
