import { addNotification } from "@iolabs/notifier";
import { DispatchAction } from "@iolabs/redux-utils";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { IViewerProxyResponse } from "../../packages/Api/data/viewer/types";
import { useKeycloak } from "@react-keycloak/web";
import { CircularProgress } from "@material-ui/core";
import { ViewerState } from "./types";
import { getLoadTestFiles, saveTransformations } from "../../packages/Api/data/viewerLoadTest/client";
import ViewerLoadTest from "./ViewerLoadTest";
import { IViewerProxyExtendedResponse } from "../../packages/Api/data/viewerLoadTest/types";
import { ICurrentModelState } from "./ViewerLoadTestCard";

interface IViewerLoadTestWrapperProps {
}

const ViewerLoadTestWrapper: React.FC<IViewerLoadTestWrapperProps> = ( { } ) => {

    const { keycloak, initialized: keycloakInitialized } = useKeycloak();

    const [modelInfo, setModelInfo] = useState<IViewerProxyExtendedResponse[]>();

    const [loading, setLoading] = useState<boolean>(false);
    const [saving, setSaving] = useState<boolean>(false);

    const dispatch = useDispatch<DispatchAction>();


    useEffect(() => {
        if (keycloak?.token) {
            setLoading(true);
            getLoadTestFiles(keycloak.token)
                .then((response: IViewerProxyExtendedResponse[]) => {
                    setModelInfo(response);
                })
                .catch((error) => {
                    console.error("Error viewer proxy: " + error);
                })
                .finally(() => {
                    setLoading(false);
                });
        }

        return () => {
            setModelInfo(undefined);
        };
    }, [keycloak, keycloakInitialized]);

    const handleSaveState = (state: ICurrentModelState[]) => {
        if (keycloak?.token) {
            setSaving(true);
            saveTransformations(keycloak.token, state)
                .then((response) => {
                    dispatch(
                        addNotification({
                            notification: {
                                variant: "success",
                                message: "State saved",
                            },
                        })
                    );

                })
                .catch((error) => {
                    console.error("Error viewer proxy: " + error);
                })
                .finally(() => {
                    setSaving(false);
                });
        }
    }


    return (modelInfo && !loading) ? (
        <ViewerLoadTest modelsInfo={modelInfo} onSaveState={handleSaveState} />
    ) : (
        <CircularProgress />
    );
};

export default ViewerLoadTestWrapper;