import { Box, Button, Typography } from "@material-ui/core";
import { createStyles, Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import React from "react";
import { useIntl } from "react-intl";
import { Link as RouterLink } from "react-router-dom";
import { fontFamily } from "../../theme/Theme";
import globalMessages from "../App/messages";
import Icon from "../Icon/Icon";
import { IIcon } from "../Icon/IconSet";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dashboardItem: {
            height: "100%",
            width: "100%",
            display: "flex",
            flexFlow: "column nowrap",
        },
        iconBox: {
            color: theme.palette.common.white,
            background: theme.palette.primary.main,
            flex: 0.5,
            padding: theme.spacing(2),
            overflow: "hidden",
            position: "relative",
            "& svg": {
                position: "absolute",
                top: 0,
                right: -5,
            },
        },
        buttonBox: {
            display: "flex",
            alignItems: "flex-start",
            flexFlow: "column",
            flex: 0.5,
            padding: theme.spacing(2),
            background: theme.palette.common.white,
        },
        typography: {
            fontFamily: fontFamily.secondary,
            fontSize: theme.typography.pxToRem(18),
            lineHeight: "1.3em",
            color: theme.palette.common.black,
            flex: 1,
            [theme.breakpoints.up("md")]: {
                fontSize: theme.typography.pxToRem(28),
                paddingRight: "40px",
            },
        },
        button: {
            [theme.breakpoints.up("md")]: {
                padding: theme.spacing(0.5, 2),
            },
            [theme.breakpoints.up("lg")]: {
                padding: theme.spacing(0.8, 3),
            },
        },
        disabled: {
            opacity: "0.75",
            "& svg, div": {
                opacity: "0.75",
            },
        },
    })
);

interface IModuleCardProps {
    index: string | number;
    path?: string;
    callback?: () => void;
    disabled?: boolean;
    icon: IIcon;
    iconSize: number;
    title: string;
    openInNewWindow?: boolean;
}

const ModuleCard: React.FC<IModuleCardProps> = ({ index, path, callback, disabled, icon, iconSize, title, openInNewWindow }) => {
    const classes = useStyles();

    // translations
    const intl = useIntl();
    const transTileButton = intl.formatMessage({ ...globalMessages.tileButtonOpen });

    return (
        <Box
            className={clsx(classes.dashboardItem, {
                [classes.disabled]: disabled,
            })}
            key={index}
        >
            <Box className={classes.iconBox}>
                <Icon name={icon} size={iconSize} />
            </Box>
            <Box className={classes.buttonBox}>
                <Typography variant="h2" className={classes.typography}>
                    {title}
                </Typography>
                {path ? (
                    <Button
                        variant="outlined"
                        component={RouterLink}
                        to={path}
                        target={openInNewWindow ? "_blank" : "_self"}
                        className={classes.button}
                        disabled={disabled}
                    >
                        {transTileButton}
                    </Button>
                ) : (
                    <Button
                        variant="outlined"
                        onClick={callback}
                        className={classes.button}
                        disabled={disabled}
                    >
                        {transTileButton}
                    </Button>
                )}
            </Box>
        </Box>
    );
};
export default ModuleCard;
