import { defineMessages } from "react-intl";

export default defineMessages({
    headerTitle: {
        id: "page.viewer.headerTitle",
        defaultMessage: "Geometry viewer",
    },
    noFileToDisplay: {
        id: "page.viewer.noFileToDisplay",
        defaultMessage: "No file to display",
    },
});
