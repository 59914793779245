import { OnLoadPropertiesCallback } from "./Viewing.Extensions.CustomPropertiesExtension";
import { union, xor } from "lodash";

export class CustomPropertiesPanel extends Autodesk.Viewing.UI.PropertyPanel {
    private viewer: Autodesk.Viewing.GuiViewer3D;

    private onLoadProperties?: OnLoadPropertiesCallback;
    private loadingElement: HTMLElement;
    private lastSelection;

    constructor(viewer: Autodesk.Viewing.GuiViewer3D, onLoadProperties?: OnLoadPropertiesCallback) {
        super(<HTMLElement>viewer.container, "custom-properties", "Custom properties");
        this.viewer = viewer;
        this.onLoadProperties = onLoadProperties;
        this.lastSelection = [];
        this.loadingElement = this.createLoading();
        this.viewer.addEventListener(Autodesk.Viewing.SELECTION_CHANGED_EVENT, (selection: any) => this.handleSelection());
        this.viewer.addEventListener(Autodesk.Viewing.ISOLATE_EVENT, (isolation: any) => this.handleSelection());

        this.handleSelection();

        this.container.style.resize = "none";
        this.container.style.left = "50px";
        this.container.style.top = "50px";
        this.container.style.height = "300px";
        this.container.style.width = "500px";
    }

    unload() {
        this.viewer.removeEventListener(Autodesk.Viewing.SELECTION_CHANGED_EVENT, (selection: any) => this.handleSelection());
        this.viewer.removeEventListener(Autodesk.Viewing.ISOLATE_EVENT, (isolation: any) => this.handleSelection());
    }

    handleSelection(/*selection: number[]*/) {
        const isolations = this.viewer.getIsolatedNodes();
        const selections = this.viewer.getSelection();

        let selection = union(isolations, selections);

        if (!selection?.length) {
            selection = [this.viewer.model.getRootId()];
        }

        if (this.onLoadProperties && xor(selection, this.lastSelection).length > 0) {
            this.showLoading();
            this.lastSelection = selection;
            this.onLoadProperties(selection, this.setProps.bind(this));
        }
    }

    setProps(props: any) {
        this.setProperties(props);
        this.hideLoading();
    }

    createLoading(): HTMLElement {
        const loading = document.createElement("div");
        loading.id = "loading-spinner";
        loading.textContent = "loading...";
        return loading;
    }

    showLoading() {
        try {
            this.scrollContainer?.appendChild(this.loadingElement);
        }
        catch (e) {

        }
    }
    hideLoading() {
        try {
            this.scrollContainer?.removeChild(this.loadingElement);
        }
        catch (e) {

        }
    }
}
