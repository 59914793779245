import { defineMessages } from "react-intl";

export default defineMessages({
    loading: {
        id: "components.synchronizer.loading",
        defaultMessage: "Loading files to sync",
    },
    noFiles: {
        id: "components.synchronizer.noFiles",
        defaultMessage: "No files to sync",
    },
    synchronize: {
        id: "components.synchronizer.synchronize",
        defaultMessage: "Synchronize",
    },
    reset: {
        id: "components.synchronizer.reset",
        defaultMessage: "Reset",
    },
});
