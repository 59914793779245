import deepmerge from "deepmerge";

export const ExtensionID = "Viewing.Extension.NodesRenamerExtension";

let isRegistered = false;

export interface INodesRenamerExtensionOptions {
    props: string[],
    glue: string,
}

const DefaultOptions: INodesRenamerExtensionOptions = {
    props: ["AIMD_PARTNO", "Beschreibung", "AIMD_DESCRIPTION"],
    glue: " "
};

export const getProperty = (attributeName: string, properties: Autodesk.Viewing.Property[], parentProperties?: Autodesk.Viewing.Property[]): Autodesk.Viewing.Property|undefined => {
    let property = properties.find(x => x.attributeName.toLowerCase() === attributeName.toLowerCase());
    if (!property && parentProperties) {
        property = parentProperties.find(x => x.attributeName.toLowerCase() === attributeName.toLowerCase());
    }
    return property;
}


export const register = () => {
    if (isRegistered) return;

    class NodesRenamerExtension extends Autodesk.Viewing.Extension {

        options: INodesRenamerExtensionOptions;

        private getNodeNameOriginal?: (dbId: number) => string;

        private names: {[key: number]: string};

        constructor(viewer: Autodesk.Viewing.GuiViewer3D, options?: INodesRenamerExtensionOptions) {
            const opts = options ? deepmerge(DefaultOptions, options) : DefaultOptions;
            super(viewer, opts);
            this.options = opts as INodesRenamerExtensionOptions;
            this.viewer = viewer;
            this.names = {};
            if (viewer.model?.getInstanceTree()) {
                this.prepareNames(viewer.model)
            }
            else {
                viewer.addEventListener(Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT, this.handleInstanceTreeCreated.bind(this));
            }
        }

        unload() {
            this.viewer?.removeEventListener(Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT, this.handleInstanceTreeCreated.bind(this));
            return true;
        }

        handleInstanceTreeCreated({ model }) {
            this.prepareNames(model);
        }

        prepareNames(model: Autodesk.Viewing.Model) {

            const instanceTree: Autodesk.Viewing.InstanceTree = model.getInstanceTree();

            // remap getNodeName function
            this.getNodeNameOriginal = instanceTree.getNodeName;
            instanceTree.getNodeName = this.getNodeName.bind(this);

            const allModelIds: number[] = this.getAllModelIds(model);

            // prepare names
            model.getBulkProperties(allModelIds, { propFilter: [...this.options.props, "name"] }, (propResults: Autodesk.Viewing.PropertyResult[]) => {
                propResults.forEach((p) => {

                    const parentId = instanceTree.getNodeParentId(p.dbId);
                    let parentProperties;
                    if (parentId) {
                        parentProperties = propResults.find( pr => pr.dbId == parentId)?.properties;
                    }
                    this.prepareName(p.dbId, p.properties, parentProperties);
                })
            });
        }


        prepareName(dbId: number, properties: Autodesk.Viewing.Property[], parentProperties?: Autodesk.Viewing.Property[]) {
            const parts: any[] = [];

            this.options.props.forEach(attributeName => {
                const property: string|number|undefined = getProperty(attributeName, properties, parentProperties)?.displayValue;
                if (property) {
                    parts.push(property);
                }
            });

            this.names[dbId] = parts.join(this.options.glue);
        }

        getNodeName(dbId) {
            return this.names[dbId];
        }

        getAllModelIds(model: Autodesk.Viewing.Model): number[] {
            const instanceTree: Autodesk.Viewing.InstanceTree = model.getInstanceTree();
            if (!instanceTree) return [];
            const allDbIdsStr: string[] = Object.keys(instanceTree.nodeAccess.dbIdToIndex);
            return allDbIdsStr.map(function (id) {
                return parseInt(id);
            });
        }
    }

    // register extension - we need to do it here so extension is loaded by Viewer
    Autodesk.Viewing.theExtensionManager.registerExtension(ExtensionID, NodesRenamerExtension);

    isRegistered = true;
}
