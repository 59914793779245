import { defineMessages } from "react-intl";

export default defineMessages({
    headerTitle: {
        id: "page.dashboard.headerTitle",
        defaultMessage: "Web services",
    },
    tileIssues: {
        id: "page.dashboard.tile.issues",
        defaultMessage: "Issues Dashboard",
    },
    tileCustomAttributes: {
        id: "page.dashboard.tile.customAttributes",
        defaultMessage: "Custom Attributes",
    },
    tilePlotApp: {
        id: "page.dashboard.tile.plotApp",
        defaultMessage: "Plot App",
    },
    tileSettings: {
        id: "page.dashboard.tile.settings",
        defaultMessage: "Settings",
    },
    tileAttributesManagement: {
        id: "page.dashboard.tile.attributesManagement",
        defaultMessage: "Attributes management",
    },
});
