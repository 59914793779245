import useStyles from "./styles";
import { useKeycloak } from "@react-keycloak/web";
import ForgeViewer from "iolabs-react-forge-viewer";
import React, { useRef, useState } from "react";
import { isJwtExpired } from "../../utils/Jwt";
import { viewerToken } from "../../packages/Api/data/viewer/client";
import { Box, Chip } from "@material-ui/core";
import { Emea, ViewerState } from "./types";
import {
    ExtensionID as CustomPropertiesExtensionID,
    register as registerCustomPropertiesExtension
} from "./extensions/Viewing.Extensions.CustomProperties/Viewing.Extensions.CustomPropertiesExtension";
import {
    ExtensionID as NodesRenamerExtensionID,
    getProperty,
    register as registerNodesRenamerExtension,
} from "./extensions/Viewing.Extensions.NodeRenamerExtension/Viewing.Extensions.NodeRenamerExtension";
import { InsertDriveFile } from "@material-ui/icons";

interface IModelingProgress {
    modelInfo: ViewerState;
    viewerState?: any;
}
const MeasureExtensionID = 'Autodesk.Measure';

const prepareAttribute = (properties: Autodesk.Viewing.Property[], parentProperties: Autodesk.Viewing.Property[], attributeName:string, displayName:string, displayValue?:string|((val?: string) => string)) => {
    let property = getProperty(attributeName, properties, parentProperties);

    if(property) {
        property.displayName = displayName;
        if (typeof displayValue === "function") {
            property.displayValue = displayValue(`${property.displayValue}`);
        }
        return property
    }
    else if(displayValue) {
        return {
            "displayName": displayName,
            "displayValue": typeof displayValue === "function" ? displayValue() : displayValue,
            "displayCategory": "",
            "attributeName": "",
            "type": 0,
            "units": "",
            "hidden": false
        }
    }
    else {
        return {
            "displayName": displayName,
            "displayValue": "-",
            "displayCategory": "",
            "attributeName": "",
            "type": 0,
            "units": "",
            "hidden": false
        }
    }
}
const normalizeWeight = (value?: string) => {
    if (!value) {
        return '-';
    }
    try {
        value = value.replace(",",".");
        const regex = /([+\-]?(?:0|[1-9]\d*)(?:\.\d+)?(?:[eE][+\-]?\d+)?)\s+(.*)/
        const match = value.match(regex);
        if (match) {
            const originalNum = parseFloat(match[1]);
            const roundedNum = Number(originalNum > 1 ? originalNum.toFixed(2) : originalNum.toPrecision(2));
            return `${roundedNum} ${match[2]}`;
        }
        else {
            return value;
        }
    }
    catch (e) {
        return value;
    }
}

const prepareAttributes = (properties: Autodesk.Viewing.Property[], parentProperties: Autodesk.Viewing.Property[], data: Autodesk.Viewing.PropertyResult) => {
    return [
        prepareAttribute(properties, parentProperties,"Name", "Name", data.name),
        prepareAttribute(properties, parentProperties, "Beschreibung", "Beschreibung"),
        prepareAttribute(properties, parentProperties, "AIMD_DESCRIPTION", "Materialbeschreibung"),
        prepareAttribute(properties, parentProperties, "AIMD_PARTNO", "Materianummer"),
        prepareAttribute(properties, parentProperties, "WEC_Docbeschreibung", "BeschreibungDE"),
        prepareAttribute(properties, parentProperties, "Masse", "Gewicht", normalizeWeight),
    ]
}

const loadAttributes = (viewer: Autodesk.Viewing.Viewer3D, id: number): Promise<Autodesk.Viewing.Property[]> => {
    return new Promise<Autodesk.Viewing.Property[]>((resolve, reject) => {
        viewer.getProperties(id, (data) => {
            const tree = viewer.model.getInstanceTree();
            const parentId = tree.getNodeParentId(id);
            if (parentId) {
                viewer.getProperties(parentId, (parentData) => {
                    resolve(prepareAttributes(data.properties, parentData.properties, data));
                });
            }
            else {
                resolve(prepareAttributes(data.properties, [], data));
            }
        });
    });
}

const Viewer: React.FC<IModelingProgress> = ({modelInfo, viewerState}) => {

    const classes = useStyles();

    const { keycloak, initialized: keycloakInitialized } = useKeycloak();

    const [modelLoaded, setModelLoaded] = useState<boolean>(false);
    const [viewer, setViewer] = useState<Autodesk.Viewing.Viewer3D>();
    const [forgeToken, setForgeToken] = useState<string>();
    const [documentName, setDocumentName] = useState<string>();

    // viewables
    const [viewable, setViewable] = useState<any>();
    const [viewables, setViewables] = useState<any>();
    const viewableRef = useRef(viewable);


    const handleTokenRequested = (onAccessToken: any) => {
        if (!forgeToken || isJwtExpired(forgeToken, 30)) {
            viewerToken(keycloak?.token as string).then((response) => {
                setForgeToken(response);
                onAccessToken(response);
            })
        }
        else {
            onAccessToken(forgeToken);
        }
    };


    const handleDocumentLoaded = (doc: any, viewables: any[]) => {
        setDocumentName(getDocumentName(doc));
        setViewable(viewables[0]);
        setViewables(viewables);
    };
    const getDocumentName = (doc: Autodesk.Viewing.Document) => {
        const docName = doc.getRoot()?.children?.[0]?.name();
        // todo original file name and location as custom attribute
        const regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}-/i;
        return docName?.replace(regex, "");
    }

    const handleForgeScriptLoaded = () => {
        registerCustomPropertiesExtension();
        registerNodesRenamerExtension();
    };

    const handleModelError = (error) => {
        console.error("Error loading the model: " + error);
    };


    const handleViewerLoaded = (viewer: Autodesk.Viewing.Viewer3D) => {
        setViewer(viewer);

        viewer.loadExtension(NodesRenamerExtensionID);

        // modify measurements extension
        viewer.addEventListener(Autodesk.Viewing.EXTENSION_LOADED_EVENT, (event) => {
            if (event.extensionId === MeasureExtensionID) {
                const extension = viewer.getExtension(MeasureExtensionID) as any;
                extension.setPrecision(0);
            }
        })

        viewer.addEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, async () => {

            await viewer.loadExtension(CustomPropertiesExtensionID, {
                onLoadProperties: (ids: number[], setProps) => {
                    if (ids?.length) {
                        const id = ids[0];
                        loadAttributes(viewer, id).then((attributes) => {
                            setProps(attributes);
                        })
                    }
                }
            })
        });
    };


    const handleViewerError = (error) => {
        console.error("Error loading mobile viewer: " + error);
    };

    const handleDocumentError = (viewer: any, error: any) => {
        console.error("Error loading a document: " + error);
    };


    const setViewerBackground = (viewer: Autodesk.Viewing.Viewer3D) => {
        viewer.setLightPreset(7); // simple white
    };

    const initState = (viewer: Autodesk.Viewing.Viewer3D) => {
        if (viewerState) {
            viewer.restoreState(viewerState)
        }
    }

    const handleModelLoaded = (viewer: Autodesk.Viewing.Viewer3D, model: Autodesk.Viewing.Model) => {
        if (viewer) {
            setViewerBackground(viewer);
            initState(viewer);
            // customize
            viewer.navigation.setReverseZoomDirection(true);
        }

        setModelLoaded(true);
    };

    const onUnmount = () => {};

    return (
        <Box className={classes.viewerWrapper}>
            <Chip
                className={classes.documentName}
                label={documentName ?? "Loading..."}
                size={"small"}
                color={"primary"}
                icon={<InsertDriveFile fontSize={"small"} />}
            />
            {/*<Box>{documentName}</Box>*/}
            <ForgeViewer
                version="7.29"
                urn={modelInfo?.urn}
                api={modelInfo?.isEmea ? Emea.eu : Emea.default}
                view={viewable}
                query={{ type: "geometry" }}
                onViewerError={handleViewerError}
                onTokenRequest={handleTokenRequested}
                onDocumentLoad={handleDocumentLoaded}
                onDocumentError={handleDocumentError}
                onModelLoad={handleModelLoaded}
                onModelError={handleModelError}
                onViewerLoad={handleViewerLoaded}
                onScriptLoaded={handleForgeScriptLoaded}
                onUnmount={onUnmount}
                forgeScriptAlreadyLoaded
                config={{
                    memory: {
                        limit: 1000,
                    },
                }}
            />
        </Box>
    );
};

export default Viewer;
