import { createStyles } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            height: "100%",
            position: "absolute",
            zIndex: 10,
            pointerEvents: "none",
        },
        viewerWrapper: {
            position: "relative",
            zIndex: 0,
            height: "100%",
            width: "100%",
            display: "flex",
        },
        viewer: {
            position: "relative",
            zIndex: 0,
            height: "100%",
            width: "100%",
            flexGrow: 1,
        },
        modelsWrapper: {
            width: "400px",
            flexGrow: 0,
            padding: "0 0.5em",
            display: "flex",
            flexDirection: "column",
        },
        models: {
            flexGrow: 1,
            overflowY: "scroll",
        },
        documentName: {
            position: "absolute",
            left: theme.spacing(1),
            top: theme.spacing(1),
            zIndex: 99,
            fontSize: theme.typography.pxToRem(12),
        },
        modelCard: {
            margin: "0.5em 0",
            fontSize: theme.typography.pxToRem(12),
            "& h5": {
                fontSize: theme.typography.pxToRem(10),
            }
        },
        modelTable: {
            fontSize: theme.typography.pxToRem(10),
            "& th": {
                textAlign: "right",
            },
            "& thead td": {
                textAlign: "center",
                fontWeight: "bold"
            },
            "& .MuiFormControl-root": {
                margin: "0px 3px",
                "& input": {
                    textAlign: "right",
                }
            }
        },
        cardActions: {
            textAlign: "right"
        },
        submitBtn: {
            margin: "0.5em 0",
            height: "100px",
            flexGrow: 0,
        }
    })
);

export default useStyles;
