import { IonRouterOutlet } from "@ionic/react";
import { AppBar, Box, Typography, useMediaQuery } from "@material-ui/core";
import { createStyles, Theme, useTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { useIntl } from "react-intl";
import { Route, useRouteMatch } from "react-router";
import Footer from "../../components/Footer/Footer";
import { StylesProps } from "../../components/Layout/Layout";
import ModuleCard from "../../components/ModuleCard/ModuleCard";
import messages from "../DashboardPage/messages";
import { Path } from "../PageRouter";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            height: "100%",
        },
        content: {
            height: "calc(100% - 20px)",
            position: "relative",
        },
        footerBar: (props: StylesProps | undefined) => ({
            height: "20px",
            background: theme.palette.common.black,
            color: theme.palette.common.white,
            zIndex: theme.zIndex.drawer - 1,
            top: "auto",
            bottom: 0,
            transition: theme.transitions.create(["margin", "width"], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        }),
    })
);
const MinimalisticPage: React.FC = () => {
    const classes = useStyles();
    const match = useRouteMatch();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("md"));

    const intl = useIntl();

    return (
        <Box className={classes.root}>
            <Box className={classes.content}>
                <IonRouterOutlet>
                    <Route
                        render={() => {
                            return (
                                <Box>
                                    <Typography>default</Typography>
                                </Box>
                            );
                        }}
                    />
                </IonRouterOutlet>
            </Box>

            <AppBar component="footer" elevation={0} className={classes.footerBar}>
                <Footer
                    hideLasUpdate={true}
                    stylesProps={{
                        // footerHeight: stylesProps?.footerHeight,
                        footerBarColor: theme.palette.common.black,
                        footerBarBackground: theme.palette.common.white,
                    }}
                />
            </AppBar>
        </Box>
    );
};

export default MinimalisticPage;
