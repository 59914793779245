import { addNotification } from "@iolabs/notifier";
import { DispatchAction } from "@iolabs/redux-utils";
import { Box, Typography, useMediaQuery } from "@material-ui/core";
import { createStyles, Theme, useTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import globalMessages from "../../components/App/messages";
import { useHeaderStyles } from "../../components/Header/Header";
import useHeaderContent from "../../components/HeaderProvider/useHeaderContent";
import ModuleCard from "../../components/ModuleCard/ModuleCard";
import Page from "../Page/Page";
import messages from "./messages";
import { IIcon } from "../../components/Icon/IconSet";
import { appPages, Path } from "../PageRouter";
import ModuleCards from "../../components/ModuleCards/ModuleCards";
import { Route } from "react-router";
import IconsPage from "../IconsPage/IconsPage";
import NotFoundPage from "../NotFoundPage/NotFoundPage";
import { IonRouterOutlet } from "@ionic/react";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dashboardBox: {
            display: "flex",
            flexFlow: "column",
            alignItems: "center",
            flex: 1,
            height: "100%",
            overflow: "auto",
            [theme.breakpoints.up("md")]: {
                flexFlow: "row wrap",
                justifyContent: "center",
                alignContent: "center",
            },
        },
        dashboardItem: {
            height: "300px",
            width: "200px",
            margin: theme.spacing(1),
            display: "flex",
            flexFlow: "column nowrap",
            justifyContent: "space-between",
            "&:last-child": {
                marginBottom: theme.spacing(4),
            },
            [theme.breakpoints.up("md")]: {
                height: "400px",
                width: "300px",
                margin: theme.spacing(2),
                "&:last-child": {
                    marginBottom: theme.spacing(2),
                },
            },
        },
    })
);

interface ICockpitAction {
    title: string;
    callback: () => void;
    icon: IIcon;
}

const CockpitPage: React.FC = () => {
    const classes = useStyles();

    const dispatch = useDispatch<DispatchAction>();

    const headerClasses = useHeaderStyles();
    const { setHeaderContent } = useHeaderContent();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("md"));

       // translations
    const intl = useIntl();
    const transApp = intl.formatMessage({ ...globalMessages.progressM });
    const transHeaderTitle = intl.formatMessage({ ...messages.headerTitle });

    useEffect(() => {
        setHeaderContent(
            <Typography variant="h1" component="h1" className={headerClasses.heading}>
                {transHeaderTitle}
            </Typography>
        );
    }, []);

    return (
        <Page>
            <Helmet>
                <title>{`${transApp} - ${transHeaderTitle}`}</title>
            </Helmet>
            <Box className={classes.dashboardBox}>
                    {appPages.find(ap => ap.path == Path.COCKPIT)?.children?.map((appPage, index) => (
                        <Route
                            key={index}
                            path={appPage.path}
                            exact={appPage.exact}
                            render={(props) => {
                                return <appPage.component {...props} title={appPage.title} />;
                            }}
                        />
                    ))}
                    <Route path={Path.COCKPIT} exact={true} render={() => (
                        <ModuleCards pages={appPages.find(ap => ap.path == Path.COCKPIT)?.children} />
                    )} />


            </Box>
        </Page>
    );
};

export default CockpitPage;
