import { defineMessages } from "react-intl";

export default defineMessages({
    headerTitle: {
        id: "page.cockpitSynchronize.headerTitle",
        defaultMessage: "Synchronize",
    },
    scanStarted: {
        id: "page.cockpitSynchronize.scanStarted",
        defaultMessage: "Scanning for new files has started",
    }

});
