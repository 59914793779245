import { DispatchAction } from "@iolabs/redux-utils";
import { Box, Typography, useMediaQuery } from "@material-ui/core";
import { createStyles, Theme, useTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import globalMessages from "../../components/App/messages";
import { useHeaderStyles } from "../../components/Header/Header";
import useHeaderContent from "../../components/HeaderProvider/useHeaderContent";
import Page from "../Page/Page";
import messages from "./messages";
import { IIcon } from "../../components/Icon/IconSet";
import Synchronizer from "../../components/Synchronizer/Synchronizer";
import ViewerLoadTestWrapper from "../../components/Viewer/ViewerLoadTestWrapper";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dashboardBox: {
            height: "100%",
            width: "100%",
            overflow: "auto",
            [theme.breakpoints.up("md")]: {
                flexFlow: "row wrap",
                justifyContent: "center",
                alignContent: "center",
            },
        },
    })
);

interface ICockpitAction {
    title: string;
    callback: () => void;
    icon: IIcon;
}

const ViewerLoadTestPage: React.FC = () => {
    const classes = useStyles();

    const dispatch = useDispatch<DispatchAction>();

    const headerClasses = useHeaderStyles();
    const { setHeaderContent } = useHeaderContent();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("md"));

       // translations
    const intl = useIntl();
    const transApp = intl.formatMessage({ ...globalMessages.progressM });
    const transHeaderTitle = intl.formatMessage({ ...messages.headerTitle });


    const translateTitle = (page: ICockpitAction): string => {
        switch (page?.title) {
            default:
                return page.title;
        }
    };

    useEffect(() => {
        setHeaderContent(
            <Typography variant="h1" component="h1" className={headerClasses.heading}>
                {transHeaderTitle}
            </Typography>
        );
    }, []);

    return (
        <Page noPadding={true}>
            <Helmet>
                <title>{`${transApp} - ${transHeaderTitle}`}</title>
            </Helmet>
            <Box className={classes.dashboardBox}>
                <ViewerLoadTestWrapper />
            </Box>
        </Page>
    );
};

export default ViewerLoadTestPage;
