import { Box, Typography, useMediaQuery } from "@material-ui/core";
import { createStyles, Theme, useTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";
import globalMessages from "../../components/App/messages";
import { useHeaderStyles } from "../../components/Header/Header";
import useHeaderContent from "../../components/HeaderProvider/useHeaderContent";
import ModuleCard from "../../components/ModuleCard/ModuleCard";
import Page from "../Page/Page";
import { AppPage, appPages, Path } from "../PageRouter";
import messages from "./messages";
import ViewerLoaderWrapper from "../../components/Viewer/ViewerLoaderWrapper";
import { useParams } from "react-router";
import { IPageParams } from "../type";
import { ViewerState } from "../../components/Viewer/types";
import Viewer from "../../components/Viewer/Viewer";
import { Alert } from "@material-ui/lab";
import { encodeUrnToBase64 } from "../../components/Viewer/utils";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dashboardBox: {
            display: "flex",
            flexFlow: "column",
            alignItems: "center",
            flex: 1,
            height: "100%",
            overflow: "auto",
            [theme.breakpoints.up("md")]: {
                flexFlow: "row wrap",
                justifyContent: "center",
                alignContent: "center",
            },
        },
        dashboardItem: {
            height: "300px",
            width: "200px",
            margin: theme.spacing(1),
            display: "flex",
            flexFlow: "column nowrap",
            justifyContent: "space-between",
            "&:last-child": {
                marginBottom: theme.spacing(4),
            },
            [theme.breakpoints.up("md")]: {
                height: "400px",
                width: "300px",
                margin: theme.spacing(2),
                "&:last-child": {
                    marginBottom: theme.spacing(2),
                },
            },
        },
    })
);

const parseModelInfo = (fileInfo: string): ViewerState|undefined => {
    try {
        const modelInfo = JSON.parse(atob(fileInfo));
        return {
            urn: encodeUrnToBase64(modelInfo.VersionUrn),
            isEmea: modelInfo.IsEMEA
        };
    }
    catch (e) {
        console.error(e, "Unable to parse file info");
        return undefined;
    }

}

const ViewerPage: React.FC = () => {
    const classes = useStyles();
    const headerClasses = useHeaderStyles();
    const { setHeaderContent } = useHeaderContent();
    const theme = useTheme();
    const { fileInfo } = useParams<IPageParams>();
    const matches = useMediaQuery(theme.breakpoints.up("md"));


    const [modelInfo, setModelInfo] = useState<ViewerState>();

    // translations
    const intl = useIntl();
    const transApp = intl.formatMessage({ ...globalMessages.progressM });
    const transHeaderTitle = intl.formatMessage({ ...messages.headerTitle });
    const transNoFileToDisplay = intl.formatMessage({ ...messages.noFileToDisplay });

    const translateTitle = (page: AppPage): string => {
        switch (page?.path) {
            default:
                return page.title;
        }
    };

    useEffect(() => {
        setHeaderContent(
            <Typography variant="h1" component="h1" className={headerClasses.heading}>
                {transHeaderTitle}
            </Typography>
        );
    }, []);

    useEffect(() => {
        if (fileInfo) {
            setModelInfo(parseModelInfo(fileInfo));
        }
        else {
            setModelInfo(undefined);
        }
    },[fileInfo])

    return (
        <Page noPadding={true}>
            <Helmet>
                <title>{`${transApp} - ${transHeaderTitle}`}</title>
            </Helmet>
            <Box className={classes.dashboardBox}>
                {modelInfo ? (
                    <Viewer
                        modelInfo={modelInfo}
                    />
                ) : (
                    <Alert severity={"warning"}>{transNoFileToDisplay}</Alert>
                )}
            </Box>
        </Page>
    );
};

export default ViewerPage;
