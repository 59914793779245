/**
 * Custom theme forked form MUI default theme
 * https://material-ui.com/customization/default-theme/
 */

import "./fonts.css";
import { lighten } from "@material-ui/core"; // load custom @font-face
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";

export const fontFamily = {
    primary: "Suisse Intl",
    secondary: "Suisse Intl",
};

const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#008bcb",
            light: "#66accc",
            dark: "#006f9b",
        },
        secondary: {
            main: "#ffdd00",
            light: "#ffff56",
            dark: "#c7bb00",
        },
        success: {
            main: "#34b88d",
            light: "#35c49c",
            dark: "#008760",
            // contrastText: "",
        },
        warning: {
            main: "#ff7f00",
            light: "#f93",
        },
        error: {
            main: "#eb445a",
            light: "#ed576b",
            dark: "#cf3c4f",
            // contrastText: "",
        },
        text: {
            primary: "#000",
            secondary: "#819099",
            disabled: "#819099",
            // hint: "",
        },
        grey: {
            50: "#edf0f2", // table bg, footer bg
            100: "#dae1e6", // avatar bg
            200: "#bcc9d1", // header color
            300: "#819099", // avatar color
            400: "#f7f9fa", // table toolbar
            500: "#dcdcdc",
            600: "#2d2d2d",
            700: "#707070",
            800: "#B2B2B2",
            900: "#819099",
            A100: "#dae1e5", // border
            // A200: "",
            // A400: "",
            // A700: "",
        },
        // issue statuses
        info: {
            main: "#ffa600", // open
            50: "#bcc9d1", // voided
            100: "#087cd9", // answered, work completed
            200: "#819099", // draft
            300: "#7a77d9", // ready to inspect
            400: "#ff495c", // not approved, in dispute
        },
        common: {
            white: "#fff",
            black: "#4a4949",
        },
        background: {
            // paper: "",
            default: "#ffffff",
        },
        action: {
            // active: "",
            // hover: "",
            // hoverOpacity: number,
            // selected: "",
            // selectedOpacity: number,
            // disabled: "",
            // disabledBackground: "",
            // disabledOpacity: number,
            // focus: "",
            // focusOpacity: number,
            // activatedOpacity: number,
        },
        type: "light", // theme style switching
    },
    typography: {
        htmlFontSize: 14,
        fontSize: 14,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightBold: 700,
        fontFamily: [
            "-apple-system",
            "BlinkMacSystemFont",
            '"Segoe UI"',
            `${fontFamily.primary}`,
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(","),
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920,
        },
    },
    shape: {
        borderRadius: 4,
    },
    spacing: (factor) => `${0.6 * factor}rem`, // bootstrap strategy
    props: {
        MuiButton: {
            size: "small",
        },
        MuiFilledInput: {
            margin: "dense",
        },
        MuiFormControl: {
            margin: "dense",
        },
        MuiFormHelperText: {
            margin: "dense",
        },
        MuiIconButton: {
            size: "small",
        },
        MuiInputBase: {
            margin: "dense",
        },
        MuiInputLabel: {
            margin: "dense",
        },
        MuiListItem: {
            dense: true,
        },
        MuiOutlinedInput: {
            margin: "dense",
        },
        MuiFab: {
            size: "small",
        },
        MuiTable: {
            size: "small",
        },
        MuiTextField: {
            margin: "dense",
        },
        MuiToolbar: {
            variant: "dense",
        },
    },
});

theme.typography.h1 = {
    fontFamily: `${fontFamily.primary}, "Helvetica", "Arial", sans-serif`,
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(20),
    lineHeight: "1.5em",
    letterSpacing: "normal",

    [theme.breakpoints.down("sm")]: {
        fontSize: theme.typography.pxToRem(16),
    },
};

theme.typography.h2 = {
    fontFamily: `${fontFamily.primary}, "Helvetica", "Arial", sans-serif`,
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(28),
    lineHeight: "1.5em",
    letterSpacing: "normal",
    color: theme.palette.text.primary,

    [theme.breakpoints.down("sm")]: {
        fontSize: theme.typography.pxToRem(24),
    },
};

theme.typography.h3 = {
    fontFamily: `${fontFamily.primary}, "Helvetica", "Arial", sans-serif`,
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(20),
    lineHeight: "1.5em",
    letterSpacing: "normal",
    color: theme.palette.text.primary,

    [theme.breakpoints.down("sm")]: {
        fontSize: theme.typography.pxToRem(16),
    },
};

theme.typography.h4 = {
    fontFamily: `${fontFamily.primary}, "Helvetica", "Arial", sans-serif`,
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: "1.5em",
    letterSpacing: "normal",
    color: theme.palette.text.primary,
};

theme.typography.h5 = {
    fontFamily: `${fontFamily.primary}, "Helvetica", "Arial", sans-serif`,
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: "1.5em",
    letterSpacing: "normal",
    color: theme.palette.text.primary,
};

theme.typography.h6 = {
    fontFamily: `${fontFamily.primary}, "Helvetica", "Arial", sans-serif`,
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: "1.5em",
    letterSpacing: "normal",
    color: theme.palette.text.primary,
};

theme.typography.subtitle1 = {
    fontFamily: `${fontFamily.primary}, "Helvetica", "Arial", sans-serif`,
    fontWeight: 300,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: "1.5em",
    letterSpacing: "normal",
};

theme.typography.subtitle2 = {
    fontFamily: `${fontFamily.primary}, "Helvetica", "Arial", sans-serif`,
    fontWeight: 300,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: "1.5em",
    letterSpacing: "normal",
};

theme.typography.body1 = {
    fontFamily: `${fontFamily.primary}, "Helvetica", "Arial", sans-serif`,
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: "1.5em",
    letterSpacing: "normal",

    [theme.breakpoints.down("sm")]: {
        fontSize: theme.typography.pxToRem(12),
    },
};

theme.typography.body2 = {
    fontFamily: `${fontFamily.primary}, "Helvetica", "Arial", sans-serif`,
    fontWeight: 300,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: "1.5em",
    letterSpacing: "normal",

    [theme.breakpoints.down("sm")]: {},
};

theme.typography.button = {
    fontFamily: `${fontFamily.primary}, "Helvetica", "Arial", sans-serif`,
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: "1.5em",
    letterSpacing: "normal",

    [theme.breakpoints.down("sm")]: {},
};

theme.typography.caption = {
    fontFamily: `${fontFamily.primary}, "Helvetica", "Arial", sans-serif`,
    fontWeight: 300,
    fontSize: theme.typography.pxToRem(13),
    lineHeight: "1.5em",
    letterSpacing: "normal",

    [theme.breakpoints.down("sm")]: {},
};

theme.typography.overline = {
    fontFamily: `${fontFamily.primary}, "Helvetica", "Arial", sans-serif`,
    fontWeight: 300,
    fontSize: theme.typography.pxToRem(11),
    lineHeight: "1.5em",
    letterSpacing: "normal",

    [theme.breakpoints.down("sm")]: {},
};

theme.mixins.toolbar = {
    minHeight: 86,
    [`${theme.breakpoints.up("xs")} and (orientation: landscape)`]: {
        minHeight: 76,
    },
    [theme.breakpoints.up("sm")]: {
        minHeight: 96,
    },
};

theme.overrides = {
    MuiCssBaseline: {
        "@global": {
            "*": {
                "scrollbar-width": "thin",
            },
            // custom scrollbar
            "*::-webkit-scrollbar": {
                width: "10px !important",
                height: "10px !important",
                backgroundColor: "transparent",
            },
            "*::-webkit-scrollbar-track": {
                "-webkit-box-shadow": "none !important",
                backgroundColor: "transparent",
                borderRadius: theme.shape.borderRadius * 2,
            },
            "*::-webkit-scrollbar-thumb": {
                backgroundColor: theme.palette.grey[100],
                borderRadius: theme.shape.borderRadius * 2,
            },
            body: {
                // keep safe area margin in native devices
                marginTop: "env(safe-area-inset-top) !important",
                marginBottom: "env(safe-area-inset-bottom) !important",
                paddingRight: "0 !important",

                // forge viewer
                "& .adsk-viewing-viewer.dark-theme .adsk-button.active, .adsk-viewing-viewer.dark-theme .adsk-button:focus": {
                    color: `${theme.palette.primary.main} !important`,
                },
                "& .adsk-viewing-viewer.dark-theme .adsk-button:hover": {
                    color: `${theme.palette.primary.main} !important`,
                    border: `1px solid ${theme.palette.primary.main} !important`,
                },
                "& .adsk-viewing-viewer .adsk-toolbar": {
                    // top: "10px",
                    bottom: "10px",
                    zIndex: 9999,
                    "&>div": {
                    },
                    "& .adsk-button": {
                        width: "19px",
                        height: "19px",
                        padding: "4px",
                        "& .adsk-button-icon": {
                            fontSize: "19px",
                        }
                    }
                },
                "& [class*='MuiCheckbox-colorPrimary']": {
                    width: "23px",
                    height: "23px",
                    "& svg": {
                        fontSize: "22px",
                        fill: theme.palette.secondary.main,
                    },
                },
            },
            sup: {
                fontSize: "70%",
            },
        },
    },
    MuiTableHead: {
        root: {
            fontSize: theme.typography.pxToRem(12),
        },
    },
    MuiTableCell: {
        sizeSmall: {
            padding: "6px 12px",
        },
    },
    MuiFormControl: {
        marginDense: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
    },
    MuiOutlinedInput: {
        root: {
            "& $notchedOutline": {
                color: theme.palette.text.primary,
                borderColor: theme.palette.grey["A100"],
            },
            "&:hover $notchedOutline": {
                color: theme.palette.common.black,
                borderColor: theme.palette.grey["A100"],
            },
            "&$focused $notchedOutline": {
                color: theme.palette.common.black,
                borderColor: theme.palette.grey["A100"],
            },
            color: theme.palette.text.primary,
            minWidth: "170px",
            [theme.breakpoints.up("md")]: {
                minWidth: "270px",
            },
        },
        notchedOutline: {
            color: theme.palette.text.primary,
        },
        marginDense: {
            margin: 0,
        },
    },
    MuiFormHelperText: {
        root: {
            fontSize: theme.typography.pxToRem(11),
        },
        contained: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
    },
    MuiIconButton: {
        root: {},
        colorPrimary: {
            // color: theme.palette.common.white,
            // background: theme.palette.primary.main,
            "&:hover, &:focus": {
                // color: theme.palette.common.white,
                // background: theme.palette.primary.dark,
            },
        },
        sizeSmall: {
            padding: 0,
        },
    },
    MuiDivider: {
        root: {
            backgroundColor: theme.palette.grey["100"],
        },
    },
    MuiCheckbox: {
        // override color for secondary color checkbox
        colorSecondary: {
            color: `${theme.palette.text.secondary} !important`,
            "&$checked": {
                color: `${theme.palette.success.dark} !important`,
            },
        },
    },
    MuiInput: {
        root: {
            fontSize: theme.typography.pxToRem(12),
        },
    },
    MuiSelect: {
        root: {
            fontSize: theme.typography.pxToRem(12),
        },
    },
    MuiTextField: {
        root: {
            fontSize: theme.typography.pxToRem(12),
        },
    },
    // https://github.com/mui-org/material-ui/blob/next/packages/material-ui/src/Button/Button.js
    MuiButton: {
        contained: {
            backgroundColor: theme.palette.common.black,
            "&:hover, &:focus": {
                backgroundColor: lighten(theme.palette.common.black, 0.3),
            },
        },
        containedPrimary: {
            fontWeight: 400,
        },
        containedSizeSmall: {
            minWidth: "170px",
            padding: "8px 16px",
            fontSize: theme.typography.pxToRem(12),
        },
        containedSizeLarge: {
            fontSize: theme.typography.pxToRem(12),
            padding: "8px 20px",
            minWidth: "170px",
            [theme.breakpoints.up("md")]: {
                fontSize: theme.typography.pxToRem(14),
                padding: "8px 22px",
                minWidth: "270px",
            },
        },
    },
    MuiToolbar: {
        root: {
            minHeight: 0,
        },
        dense: {
            minHeight: 0,
        },
    },
};

export default theme;
