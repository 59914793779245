import React, { useEffect, useState } from "react";
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CircularProgress,
    Switch,
    TextField,
    Typography,
} from "@material-ui/core";
import useStyles from "./styles";
import { IVector, IViewerProxyExtendedResponse } from "../../packages/Api/data/viewerLoadTest/types";

interface IViewerLoadTestCardProps {
    modelDefinition: IViewerProxyExtendedResponse,
    viewer?: Autodesk.Viewing.Viewer3D,
    onChange?: (state: ICurrentModelState) => void,
}

export interface ICurrentModelState {
    rotation: IVector;
    offset: IVector;
    show: boolean;
    urn: string;
}

// const getModificationOptions = (modelSetup: IModelDef) => {
//     const out: any = {};
//     const transform = modelSetup?.transformation;
//     if (transform) {
//         // @ts-ignore
//
//         out.placementTransform = (new THREE.Matrix4()).makeRotationY(50);
//     }
//     else {
//     }
//
//     const offset = modelSetup?.offset;
//     if (offset) {
//         out.globalOffset = {
//             x: offset.x ?? 0,
//             y: offset.y ?? 0,
//             z: offset.z ?? 0,
//         }
//     }
//     else {
//         out.globalOffset = {
//             x: 0,
//             y: 0,
//             z: 0,
//         }
//     }
//
//     return out;
// }

function deg2rad(degrees)
{
    var pi = Math.PI;
    return degrees * (pi/180);
}

const ViewerLoadTestCard: React.FC<IViewerLoadTestCardProps> = ({ modelDefinition, viewer, onChange}) => {
    const classes = useStyles();

    const [show, setShow] = useState<boolean>(modelDefinition.show);
    const [viewable, setViewable] = useState<Autodesk.Viewing.BubbleNode>();
    const [document, setDocument] = useState<Autodesk.Viewing.Document>();
    const [model, setModel] = useState<Autodesk.Viewing.Model>();

    const [offset, setOffset] = useState<IVector>(modelDefinition.offset ?? {x:0, y:0, z:0});
    const [rotation, setRotation] = useState<IVector>(modelDefinition.rotation ?? {x:0, y:0, z:0});

    const toggleShow = () => {
        setShow(!show)
    }

    const handleChangeOffset = (axis: "x"|"y"|"z") => (event) => {
        const value = parseFloat(event.target.value);
        setOffset({...offset, [axis]: value});
    }

    const handleChangeRotation = (axis: "x"|"y"|"z") => (event) => {
        const value = parseFloat(event.target.value);
        setRotation({...rotation, [axis]: value});
    }

    const Input = (vector: "rot"|"off", axis: "x"|"y"|"z") => {
        switch (vector) {
            case "rot": return <TextField inputProps={{ step: 1 }} type="number" size="small" onChange={handleChangeRotation(axis)} value={rotation[axis]}></TextField>
            case "off": return <TextField inputProps={{ step: 10 }} type="number" size="small" onChange={handleChangeOffset(axis)} value={offset[axis]}></TextField>
        }
    }




    const loadModel = () => {
        Autodesk.Viewing.Document.load(`urn:${modelDefinition.urn}`, (doc) => {
            setDocument(doc);
            // var viewable = doc.getRoot().getDefaultGeometry();
            var viewable = doc.getRoot().search({type:'geometry', role: '3d'})[0];
            setViewable(viewable);

        }, (err) => {
            console.error(err)
        });
    }

    const placeModel = () => {
        if (viewer && document && viewable) {
            const loadOptions = {
                preserveView: true,
                keepCurrentModels: true,
                globalOffset: {
                    x: 0,
                    y: 0,
                    z: 0,
                }

            }
            viewer.loadDocumentNode(document, viewable, loadOptions).then( (model) => {
                setModel(model);
                viewer.fitToView();
            } );
        }
    }

    const clearModel = () => {
        viewer?.unloadDocumentNode(viewable);
    }

    const transformModel = () => {
        if (viewer && model) {
            let xform = new THREE.Matrix4();
            // modify the xform instead of resetting it, for example
            if (rotation) {
                if (rotation.x) {
                    xform.multiply((new THREE.Matrix4()).makeRotationX(deg2rad(rotation.x)));
                }
                if (rotation.y) {
                    xform.multiply((new THREE.Matrix4()).makeRotationY(deg2rad(rotation.y)))
                }
                if (rotation.z) {
                    xform.multiply((new THREE.Matrix4()).makeRotationZ(deg2rad(rotation.z)))
                }
            }

            if (offset) {
                let placement = new THREE.Matrix4();
                placement.makeTranslation(offset.x, offset.y, offset.z);
                xform.multiply(placement);
            }

            // @ts-ignore
            model.setModelTransform(xform);
            viewer.impl.invalidate(true, true, true);
        }
    }

    useEffect(() => {
        if (onChange) {
            onChange({
                offset: offset,
                rotation: rotation,
                show: show,
                urn: modelDefinition.urn
            })
        }
    }, [modelDefinition, offset, rotation, show]);

    useEffect(() => {
        if (viewer && modelDefinition) {
            loadModel();
        }
    }, [viewer, modelDefinition]);

    useEffect(() => {
        if (show) {
            placeModel()
        }
        else {
            clearModel();
        }
    }, [show]);

    useEffect(() => {
        if (show) {
            placeModel();
        }
    }, [viewable]);


    useEffect(() => {
        if (show) {
            transformModel();
        }
    }, [model]);

    useEffect(() => {
        transformModel();
    }, [offset, rotation]);

    return (
        <Card className={classes.modelCard}>
            <CardContent>
                <Typography variant={"h5"}>{modelDefinition.name}</Typography>
                <table className={classes.modelTable}>
                    <thead>
                    <tr>
                        <th></th>
                        <td>x</td>
                        <td>y</td>
                        <td>z</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <th>rotation</th>
                        <td>
                            {Input("rot", "x")}
                        </td>
                        <td>
                            {Input("rot", "y")}
                        </td>
                        <td>
                            {Input("rot", "z")}
                        </td>
                    </tr>
                    <tr>
                        <th>offset</th>
                        <td>
                            {Input("off", "x")}
                        </td>
                        <td>
                            {Input("off", "y")}
                        </td>
                        <td>
                            {Input("off", "z")}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </CardContent>
            <CardActions className={classes.cardActions}>
                <Switch size={"small"} onChange={toggleShow} checked={!!show}/>
                {!viewable && <CircularProgress size={20} />}
            </CardActions>
        </Card>
    )
}
export default ViewerLoadTestCard;