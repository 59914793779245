import useStyles from "./styles";
import { useKeycloak } from "@react-keycloak/web";
import React, { useEffect, useRef, useState } from "react";
import { isJwtExpired } from "../../utils/Jwt";
import { viewerToken } from "../../packages/Api/data/viewer/client";
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader, CircularProgress,
    Switch,
    TextField,
    Typography,
} from "@material-ui/core";
import { IVector, IViewerProxyExtendedResponse } from "../../packages/Api/data/viewerLoadTest/types";
import ViewerLoadTestCard, { ICurrentModelState } from "./ViewerLoadTestCard";


interface IViewerLoadTestProps {
    modelsInfo: IViewerProxyExtendedResponse[];
    viewerState?: any;
    onSaveState?: (state: ICurrentModelState[]) => void;
}



interface IModelDef {
    urn: string;
    transformation: IVector;
    offset: IVector;
    show: boolean;
    doc: Autodesk.Viewing.Document;
    viewable: Autodesk.Viewing.BubbleNode
}

const ViewerLoadTest: React.FC<IViewerLoadTestProps> = ({modelsInfo, onSaveState}) => {

    const classes = useStyles();

    const { keycloak, initialized: keycloakInitialized } = useKeycloak();

    const [viewer, setViewer] = useState<Autodesk.Viewing.Viewer3D>();

    const [transformations, setTransformations] = useState<ICurrentModelState[]>(modelsInfo.map(mi => ({
        offset: mi.offset,
        rotation: mi.rotation,
        show: mi.show,
        urn: mi.urn
    })));


    const [forgeToken, setForgeToken] = useState<string>();

    const viewerDiv = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (!forgeToken || isJwtExpired(forgeToken, 30)) {
            viewerToken(keycloak?.token as string).then((response) => {
                setForgeToken(response);
            })
        }
    }, [keycloak?.token]);

    const loadManifest = (documentId ) => {
        return new Promise(( resolve, reject ) => {
            const onDocumentLoadSuccess = ( doc ) => {
                // resolve(doc)
                doc.downloadAecModelData(() => resolve(doc));
            };
            Autodesk.Viewing.Document.load( documentId, onDocumentLoadSuccess, reject );
        });
    }




    useEffect(() => {
        if (viewerDiv.current && forgeToken) {

            const viewerOptions = {
                env: 'AutodeskProduction2',
                api: 'streamingV2',
                getAccessToken: handleTokenRequested,

            };
            const options3d = {
                viewerConfig: {
                    disableBimWalkInfoIcon: true,
                }
            };

            Autodesk.Viewing.Initializer(viewerOptions, () => {

                // @ts-ignore
                const viewer = new Autodesk.Viewing.Private.GuiViewer3D(viewerDiv.current as HTMLElement);


                viewer.start();
                setViewer(viewer);


                // const view = new Autodesk.Viewing.AggregatedView();
                // view.init(viewerDiv.current as HTMLElement, options3d);
                //
                // const viewer = view.viewer;
                //
                // setViewer(viewer);
                //
                // const tasks: Promise<any>[] = [];
                // modelsInfo.forEach( md => tasks.push( loadManifest( `urn:${md.urn}` ) ) );
                //
                //
                // Promise.all(tasks)
                //     .then( docs =>  Promise.resolve( docs.map( doc => {
                //         const bubbles = doc.getRoot().search({type:'geometry', role: '3d'});
                //         const bubble = bubbles[0];
                //         if( !bubble ) return null;
                //
                //         return bubble;
                //     })))
                //     .then( bubbles => {
                //         // view.switchView(bubbles, undefined);
                //         view.setNodes( bubbles, undefined )
                //     });
            });
        }
    }, [viewerDiv, forgeToken]);


    const handleTokenRequested = (onAccessToken: any) => {
        if (!forgeToken || isJwtExpired(forgeToken, 30)) {
            viewerToken(keycloak?.token as string).then((response) => {
                setForgeToken(response);
                onAccessToken(response);
            })
        }
        else {
            onAccessToken(forgeToken);
        }
    };

    const handleSyncTransformations = (model: IViewerProxyExtendedResponse) => (state: ICurrentModelState) => {
        const newTransformations : ICurrentModelState[] = [...transformations];

        const t = newTransformations.find(nt => nt.urn === model.urn);
        if (t) {
            t.rotation = state.rotation;
            t.offset = state.offset;
            t.show = state.show;
            t.urn = model.urn;
        }
        else {
            newTransformations.push(state);
        }
        console.log("XXX transformations", newTransformations);
        setTransformations(newTransformations);
    }


    const handleSaveTransformations = () => {
        if (onSaveState) {
            onSaveState(transformations);
        }
    }

    return (
        <Box className={classes.viewerWrapper}>
            {/*<Box>{documentName}</Box>*/}
            <Box className={classes.modelsWrapper}>
                <Box className={classes.models}>
                    {modelsInfo.map((model, index) => (
                        <Box key={index}>
                            <ViewerLoadTestCard viewer={viewer} modelDefinition={model} onChange={handleSyncTransformations(model)} />
                        </Box>
                    ))}
                </Box>
                <Button fullWidth={true} className={classes.submitBtn} variant={"contained"} onClick={handleSaveTransformations}>Save</Button>
            </Box>
            <div ref={viewerDiv} className={classes.viewer} />
        </Box>
    );
};

export default ViewerLoadTest;
