import { IonRouterOutlet } from "@ionic/react";
import React from "react";
import { Route } from "react-router";
import { IIcon } from "../components/Icon/IconSet";
import DashboardPage from "./DashboardPage/DashboardPage";
import IconsPage from "./IconsPage/IconsPage";
import NotFoundPage from "./NotFoundPage/NotFoundPage";
import ViewerPage from "./ViewerPage/ViewerPage";
import CockpitPage from "./CockpitPage/CockpitPage";
import CockpitSynchronizePage from "./CockpitSynchronizePage/CockpitSynchronizePage";
import { KeycloakRealmRole } from "../redux/keyclock/types";
import { useKeycloak } from "@react-keycloak/web";
import ViewerLoadTestPage from "./ViewerLoadTestPage/ViewerLoadTestPage";

export interface AppPage {
    title: string;
    path: string;
    exact?: boolean;
    icon: IIcon;
    component?: any;
    pages?: AppPage;
    wip?: boolean;
    inMenu?: boolean;
    inDashboard?: boolean;
    children?: AppPage[];
    requiredRole?: KeycloakRealmRole;
}

export enum Path {
    ROOT = "/",
    ICONS = "/icons",
    VIEWER = "/viewer/:fileInfo?",
    COCKPIT = "/cockpit",
    COCKPIT_SYNC = "/cockpit/sync",
    VIEWER_LOAD_TEST = "/load-test",
}

export const appPages: AppPage[] = [
    {
        title: "Dashboard",
        path: Path.ROOT,
        icon: "chevron-left",
        exact: true,
        component: DashboardPage,
    },
    {
        title: "Viewer",
        path: Path.VIEWER,
        icon: "cube",
        exact: true,
        inDashboard: false,
        component: ViewerPage,
    },
    {
        title: "Cockpit",
        path: Path.COCKPIT,
        icon: "cog",
        exact: false,
        inDashboard: true,
        component: CockpitPage,
        requiredRole: KeycloakRealmRole.DASHBOARD_ADMIN,
        children: [
            {
                title: "Synchronize",
                path: Path.COCKPIT_SYNC,
                icon: "target",
                exact: true,
                inDashboard: true,
                component: CockpitSynchronizePage,
                requiredRole: KeycloakRealmRole.DASHBOARD_ADMIN,
            },
        ]
    },
    {
        title: "Viewer load test",
        path: Path.VIEWER_LOAD_TEST,
        icon: "cube",
        exact: true,
        inDashboard: true,
        component: ViewerLoadTestPage,
        requiredRole: KeycloakRealmRole.DASHBOARD_ADMIN,
    },
];

export const hasRouteAccess = (keycloak: Keycloak.KeycloakInstance, appPage: AppPage) => {
    return !appPage.requiredRole || keycloak?.hasRealmRole(appPage.requiredRole);
}


const PageRouter: React.FC = () => {

    const { keycloak, initialized: keycloakInitialized } = useKeycloak();

    return (
        <IonRouterOutlet>
            {keycloakInitialized && keycloak && appPages.filter(appPage => hasRouteAccess(keycloak, appPage)).map((appPage, index) => (
                <Route
                    key={index}
                    path={appPage.path}
                    exact={appPage.exact}
                    render={(props) => {
                        return <appPage.component {...props} title={appPage.title} />;
                    }}
                />
            ))}
            <Route path={Path.ICONS} exact={true} component={IconsPage} />
            <Route component={NotFoundPage} />
        </IonRouterOutlet>
    );
};

export default PageRouter;
