import { AxiosResponse } from "axios";
import config from "../../../../config/config";
import ApiClient from "../../ApiClient";
import { IProcessSharePointFileRequest, IProcessSharePointFileResponse, ISharePointFileInfo } from "./types";

const forgeApi = new ApiClient("/admin", config.api.baseUrl);

export const scan = (
    token: string
): Promise<ISharePointFileInfo[]> => {
    ApiClient.setAuthorizationToken(token);

    const url = `/Scan`;

    return forgeApi.get(url).then((response: AxiosResponse<ISharePointFileInfo[]>) => {
        return Promise.resolve(response.data);
    });
};

export const processFile = (
    token: string,
    processFileRequest: IProcessSharePointFileRequest
): Promise<IProcessSharePointFileResponse> => {
    ApiClient.setAuthorizationToken(token);

    const url = `/ProcessFile`;

    return forgeApi.post(url, processFileRequest).then((response: AxiosResponse<IProcessSharePointFileResponse>) => {
        return Promise.resolve(response.data);
    });
};
