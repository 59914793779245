import {
    Button,
    Card,
    CardActions,
    CardContent, CircularProgress,
    LinearProgress,
    List,
    ListItem, ListItemIcon,
    ListItemText,
    Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import messages from "./messages";
import useStyles from "./styles";
import { processFile, scan } from "../../packages/Api/data/admin/client";
import { useKeycloak } from "@react-keycloak/web";
import { ISharePointFileInfo } from "../../packages/Api/data/admin/types";
import { Alert } from "@material-ui/lab";
import { v4 as uuidv4 } from 'uuid';
import Icon from "../Icon/Icon";
import { Check, Warning } from "@material-ui/icons";

enum SyncState {
    UNKNOWN,
    SYNCING,
    SUCCESS,
    ERROR
}

const Synchronizer: React.FC = () => {
    const classes = useStyles();
    const { keycloak, initialized: keycloakInitialized } = useKeycloak();

    const [ rescanCounter, setRescanCounter ] = useState<number>(1);
    const [ scanning, setScanning ] = useState<boolean>(false);
    const [ files, setFiles ] = useState<ISharePointFileInfo[]>([]);
    const [ syncState, setSyncState ] = useState<{[key: string]: SyncState}>({});
    const [ syncStarted, setSyncStarted ] = useState<boolean>(false);

    // translations
    const intl = useIntl();
    const transLoading = intl.formatMessage({ ...messages.loading });
    const transNoFiles = intl.formatMessage({ ...messages.noFiles });
    const transSynchronize = intl.formatMessage({ ...messages.synchronize });
    const transReset = intl.formatMessage({ ...messages.reset });

    useEffect(() => {
        if (keycloak?.token) {
            setScanning(true);
            scan(keycloak?.token)
                .then((files) => {
                    setFiles(files);
                })
                .finally(() => {
                    setScanning(false);
                })
        }
    }, [keycloak, rescanCounter]);

    const fileKey = (file: ISharePointFileInfo) => file.documentID

    const handleSync = () => {
        if (keycloak?.token, files?.length) {
            setSyncState({});
            setSyncStarted(true);
            const correlationId = uuidv4();
            files.forEach(file => {
                const key = fileKey(file);
                setSyncState(prevState => ({
                    ...prevState,
                    [key]: SyncState.SYNCING
                }))
                processFile(keycloak?.token as string, {
                    relativeServerPath: `${file.fileUrl}`,
                    correlationId: correlationId
                }).then((res) => {
                   setSyncState(prevState => ({
                       ...prevState,
                       [key]: res.success ? SyncState.SUCCESS : SyncState.ERROR
                   }))
                });
            })

        }
    }
    const handleReset = () => {
        setSyncStarted(false);
        setSyncState({});
        setRescanCounter(rescanCounter+1)
    }

    const getIcon = (file: ISharePointFileInfo) => {
        switch (syncState[fileKey(file)]) {
            case SyncState.SYNCING:
                return <CircularProgress size={20} />
            case SyncState.SUCCESS:
                return <Check fontSize={"small"} color={"primary"} />
            case SyncState.ERROR:
                return <Warning fontSize={"small"} color={"error"} />
            case SyncState.UNKNOWN:
            default:
                return null
        }
    }

    return (
        <Card className={classes.card}>
            <CardContent>
                {
                    scanning ? (
                        <>
                            <Typography variant={"caption"}>{transLoading}</Typography>
                            <LinearProgress />
                        </>
                    ) : (
                        files?.length ? (
                            <List>
                                {files.map(file => (
                                    <ListItem className={classes.file}  key={`file-${file.fileUrl}`}>
                                        <ListItemText className={classes.filePath}>{`${file.folderUrl}/`}</ListItemText>
                                        <ListItemText>{file.fileName}</ListItemText>
                                        <ListItemIcon className={classes.fileStatusIcon}>{getIcon(file)}</ListItemIcon>
                                    </ListItem>
                                ))}
                            </List>
                        ) : (
                            <Alert color={"warning"}>{transNoFiles}</Alert>
                        )
                    )
                }
            </CardContent>
            <CardActions className={classes.actions}>
                <Button
                    onClick={handleReset}
                    color={"default"}
                    variant={"text"}
                    size="small"
                >
                    {transReset}
                </Button>
                <Button
                    onClick={handleSync}
                    color={"primary"}
                    variant={"contained"}
                    size="small"
                    disabled={syncStarted}
                >
                    {transSynchronize}
                </Button>
            </CardActions>
        </Card>
    );
};

export default Synchronizer;
