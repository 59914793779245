import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { defaultFooterHeight } from "../Layout/Layout";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        card: {
            minWidth: "300px"
        },
        file: {
          display: "flex",
        },
        filePath: {
            color: theme.palette.text.disabled,
            flexGrow: 0
        },
        fileStatusIcon: {
            paddingLeft: theme.spacing(2)
        },
        actions: {
            justifyContent: "flex-end",
            "& .MuiButton-root": {
                minWidth: 0,
                padding: theme.spacing(0.5,1)
            }
        }
    }
)
);

export default useStyles;
