import { AxiosResponse } from "axios";
import config from "../../../../config/config";
import ApiClient from "../../ApiClient";
import { IViewerProxyExtendedResponse } from "./types";
import { ICurrentModelState } from "../../../../components/Viewer/ViewerLoadTestCard";

const forgeApi = new ApiClient("/ViewerLoadTest", config.api.baseUrl);

export const getLoadTestFiles = (
    token: string
): Promise<IViewerProxyExtendedResponse[]> => {
    ApiClient.setAuthorizationToken(token);

    const url = `/Files`;

    return forgeApi
        .get(url)
        .then((response: AxiosResponse<IViewerProxyExtendedResponse[]>) => {
            return Promise.resolve(response.data);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

export const saveTransformations = (
    token: string,
    data: ICurrentModelState[]
): Promise<void> => {
    ApiClient.setAuthorizationToken(token);

    const url = `/Transformations`;

    return forgeApi
        .post(url, data)
        .then((response: AxiosResponse<void>) => {
            return Promise.resolve();
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};